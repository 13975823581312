// Generated by Framer (2c4b293)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["W6t218Ze0", "gbx_vBjcF", "Zh8kps4Ab", "fk7v_kzC7"];

const serializationHash = "framer-cIQmi"

const variantClassNames = {fk7v_kzC7: "framer-v-1m2deqp", gbx_vBjcF: "framer-v-15qwu1z", W6t218Ze0: "framer-v-1cs5yaw", Zh8kps4Ab: "framer-v-18r5yul"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "W6t218Ze0", "Variant 2": "gbx_vBjcF", "Variant 3": "Zh8kps4Ab", "Variant 4": "fk7v_kzC7"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, dXeqikttq: image ?? props.dXeqikttq ?? {src: "https://framerusercontent.com/images/FMifVyDi8zeGudkAQCzwljchuk.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/FMifVyDi8zeGudkAQCzwljchuk.png?scale-down-to=512 512w,https://framerusercontent.com/images/FMifVyDi8zeGudkAQCzwljchuk.png 753w"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "W6t218Ze0"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, dXeqikttq, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "W6t218Ze0", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter574rs2 = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
setVariant("gbx_vBjcF")
})

const onMouseLeavedgzikl = activeVariantCallback(async (...args) => {
setGestureState({isHovered: false})
setVariant("W6t218Ze0")
})

const onTap1t4hndv = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("fk7v_kzC7")
})

const onTap146tzbj = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("Zh8kps4Ab")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://onkomamky.surge.sh"} openInNewTab><Image {...restProps} {...gestureHandlers} as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 331, intrinsicWidth: 753, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 331, pixelWidth: 753, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(dXeqikttq)}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1cs5yaw", className, classNames)} framer-1ig40rq`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"W6t218Ze0"} onMouseEnter={onMouseEnter574rs2} ref={ref ?? ref1} style={{filter: "none", WebkitFilter: "none", ...style}} variants={{fk7v_kzC7: {filter: "blur(5px)", WebkitFilter: "blur(5px)"}, gbx_vBjcF: {filter: "blur(5px)", WebkitFilter: "blur(5px)"}}} {...addPropertyOverrides({fk7v_kzC7: {"data-framer-name": "Variant 4", onMouseEnter: undefined, onTap: onTap146tzbj}, gbx_vBjcF: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeavedgzikl}, Zh8kps4Ab: {"data-framer-name": "Variant 3", onMouseEnter: undefined, onTap: onTap1t4hndv}}, baseVariant, gestureVariant)}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-cIQmi.framer-1ig40rq, .framer-cIQmi .framer-1ig40rq { display: block; }", ".framer-cIQmi.framer-1cs5yaw { height: 177px; overflow: hidden; position: relative; text-decoration: none; width: 427px; }", ".framer-cIQmi.framer-v-15qwu1z.framer-1cs5yaw { aspect-ratio: 2.413793103448276 / 1; height: var(--framer-aspect-ratio-supported, 177px); }", ".framer-cIQmi.framer-v-18r5yul.framer-1cs5yaw, .framer-cIQmi.framer-v-1m2deqp.framer-1cs5yaw { aspect-ratio: 2.413793103448276 / 1; cursor: pointer; height: var(--framer-aspect-ratio-supported, 177px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 177
 * @framerIntrinsicWidth 427
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"gbx_vBjcF":{"layout":["fixed","fixed"]},"Zh8kps4Ab":{"layout":["fixed","fixed"]},"fk7v_kzC7":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"dXeqikttq":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerUOP6QWzfL: React.ComponentType<Props> = withCSS(Component, css, "framer-cIQmi") as typeof Component;
export default FramerUOP6QWzfL;

FramerUOP6QWzfL.displayName = "onkomamky c";

FramerUOP6QWzfL.defaultProps = {height: 177, width: 427};

addPropertyControls(FramerUOP6QWzfL, {variant: {options: ["W6t218Ze0", "gbx_vBjcF", "Zh8kps4Ab", "fk7v_kzC7"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4"], title: "Variant", type: ControlType.Enum}, dXeqikttq: {__defaultAssetReference: "data:framer/asset-reference,FMifVyDi8zeGudkAQCzwljchuk.png?originalFilename=onkomamky_logo_vsetky_varianty_krivky-5-removebg-preview.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerUOP6QWzfL, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})